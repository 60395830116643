import axios from 'axios';
import { Link, useHistory } from "react-router-dom";


export const addLinkedAccount = (token, metadata) => async (dispatch, getState) => {
    const jwt = getState().token

    const formBody = [encodeURIComponent("public_token") + '=' + encodeURIComponent(token)]
    try {
        const result = await fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/dashboard/add/account`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                "Authorization": `Bearer ${jwt}`
            },
            body: formBody
        })
        console.log(result)
        dispatch(fetchDashboardData())
    } catch (error) {
        console.log(error);
        // window.location.href = '/';
        dispatch({ type: 'DASHBOARD_DATA_FAIL', error: error.message })
    }
    // console.log(token, metadata)
};

export const addGuestLinkedAccount = (token, metadata, id, pin) => async (dispatch, getState) => {
    const jwt = getState().token
    try {

        const params = {
            public_token: token,
            request_id: id,
            request_pin: pin
        };

        const result = await axios.post(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/guest/add/account`, params, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            },  
        });

        console.log(result)
        dispatch(fetchGuestData(token, id, pin))
    } catch (error) {
        console.log(error);
        // window.location.href = '/';
        dispatch({ type: 'GUEST_DATA_FAIL', error: error.message })
    }
    console.log(token, metadata)
};

export const fetchDashboardData = () => async (dispatch, getState) => {
    const jwt = getState().token

    dispatch({ type: 'DASHBOARD_DATA_LOADING' })
    
    try {
        const result = await fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/dashboard`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            }
        })

        .then(res => res.json());
        console.log(result);
        
        dispatch({ type: 'DASHBOARD_DATA_LOADED', data: result })

    } catch (error) {
        console.log(error);
        window.location.href = '/dashboard';
        dispatch({ type: 'DASHBOARD_DATA_FAIL', error: error.message })
    }

}

export const fetchGuestData = (token, id, pin) => async (dispatch, getState) => {
    const jwt = getState().token

    dispatch({ type: 'GUEST_DATA_LOADING' })
    
    try {

        const params = {
            public_token: token,
            request_id: id,
            request_pin: pin
        };

        const result = await axios.post(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/guest/get/account`, params, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`
            },  
        });

        console.log(result)
        
        dispatch({ type: 'GUEST_DATA_LOADED', data: result.data })

    } catch (error) {
        console.log(error);
        dispatch({ type: 'GUEST_DATA_FAIL', error: error.message })
    }

}

export const clearFetchGuestDataError = () => async (dispatch, getState) => {

    dispatch({ type: 'GUEST_DATA_CLEAR', data: null })

}

export const clearDashboardError = () => async (dispatch, getState) => {

    dispatch({ type: 'DASHBOARD_DATA_CLEAR', data: null })

}

export const sendReportData = (sendReportObj) => async (dispatch, getState) => {
    const jwt = getState().token;

    console.log('sendReportObj', sendReportObj)

    try {
        const result = await axios.post(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/communications/create/report`, sendReportObj, {
            headers: {
                "Authorization": `Bearer ${jwt}`,
            }
        })

        dispatch({ type: 'SEND_REPORT_DONE', data: result.data })
        window.location.href = '/dashboard';

    } catch (error) {
        console.log(error)
        dispatch({ type: 'SEND_REPORT_FAIL', error: error.message })
    }

}

export const sendGuestReportData = (sendReportObj) => async (dispatch, getState) => {
    const jwt = getState().token;

    console.log(sendReportObj);

    try {
        const result = await axios.post(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/guest/communications/create/report`, sendReportObj, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`,
            },
            
        })

        console.log(result.data);
        
        dispatch({ type: 'SEND_GUEST_REPORT_DONE', data: result.data })
        window.location.href = '/processcomplete';

    } catch (error) {
        console.log(error)
        dispatch({ type: 'SEND_GUEST_REPORT_FAIL', error: error.message })
    }

}

export const buildReportRequest = (buildReportObj) => async (dispatch, getState) => {
    const jwt = getState().token;

    console.log(jwt)

    try {
        const result = await axios.post(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/plaid_asset_report_request`, buildReportObj, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`,
            },
            
        })

        console.log(result);
        
        if(result.data.statusText === "success"){
            dispatch({ type: 'BUILD_REPORT_REQUEST_DONE', data: result.data })
        } else {
            dispatch({ type: 'BUILD_REPORT_REQUEST_FAIL', data: result.data })
        }

    } catch (error) {
        console.log(error)
        dispatch({ type: 'BUILD_REPORT_REQUEST_FAIL', error: error.message })
    }

}

export const buildGuestReportRequest = (buildReportObj) => async (dispatch, getState) => {
    const jwt = getState().token;

    // console.log(jwt)

    try {
        const result = await axios.post(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/guest/plaid_asset_report_request`, buildReportObj, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${jwt}`,
            },
            
        })

        console.log(result);
        
        if(result.data.status === "success"){
            dispatch({ type: 'BUILD_GUEST_REPORT_REQUEST_DONE', data: result.data })
        } else {
            dispatch({ type: 'BUILD_GUEST_REPORT_REQUEST_FAIL', data: result.data })
        }

    } catch (error) {
        console.log(error)
        dispatch({ type: 'BUILD_GUEST_REPORT_REQUEST_FAIL', error: error.message })
    }

}

export const clearSendReportError = () => async (dispatch, getState) => {

    dispatch({ type: 'SEND_REPORT_CLEAR', data: null })

}

export const updateNameAndAddress = (firstname, lastname, address) => async (dispatch, getState) => {
    const jwt = getState().token

    const params = {
        fname: firstname,
        lname: lastname,
        address: address
      };

    console.log("~~~~~~~");
    console.log(process.env.REACT_APP_PAYVIEW_BASE_URL);
    console.log("~~~~~~~");

    try {
        const result = await fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/myaccount`, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                "Authorization": `Bearer ${jwt}`
            },
            body: JSON.stringify(params)
        })
        console.log(result)
        window.location.href = '/dashboard';
    } catch (error) {
        console.log(error);
    }
};
