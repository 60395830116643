import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useS } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle } from "@material-ui/core";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Layout from "../Layout";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Create from "@material-ui/icons/Create";
import { fetchDashboardData, fetchAccountData, sendReportData, sendGuestReportData, clearSendReportError, clearFetchGuestDataError } from "../../actions/addLinkedAccounts";
import Transactions from "../../data/transactions.json";
import clsx from "clsx";


const withStyles = makeStyles(() => ({
  confirmed_block: {
    display: "flex",
    alignItems: "center",
  },
  check_circle: {
    width: "31px",
    height: "31px",
    float: "left",
    backgroundColor: "RGB(50, 161, 159)",
    borderRadius: "50%",
    boxShadow: "0 6px 22px 0 RGBA(126, 126, 126, 0.5)",
    minWidth: "31px",
    "& svg": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "70%",
      margin: "0 auto",
    },
  },
  confirmed_block_p: {
    color: "#313131",
    fontSize: "14px",
    fontWeight: "400",
    float: "left",
    marginLeft: "10px",
  },
  transaction_block: {
    margin: "0",
    padding: "0",
    listStyleType: "none",
    "& li": {
      borderBottom: "1px solid RGB(228, 226, 226)",
      fontSize: "14px",
      fontWeight: "400",
      color: "#313131",
      display: "flex",
      justifyContent: "space-between",
      margin: "10px 0",
      paddingBottom: "10px",
    },
    "& a": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#32a19f",
      textDecoration: "none",
      "& :hover": {
        textDecoration: "none",
      },
    },
  },
  transaction_details: {
    borderRadius: "20px",
    overflowY: "scroll",
    height: "500px",
    padding: "15px",
    backgroundColor: "#fff",
    boxShadow: "0 6px 22px 0 RGBA(91, 91, 91, 0.25)",
    margin: "20px",
    "& h2": {
      fontSize: "14px",
      fontWeight: "400",
      color: "rgb(72,72,72)",
      textAlign: "left",
      marginTop: "0",
    },
    "& p": {
      fontSize: "11px",
      fontWeight: "700",
      color: "#000",
      textAlign: "left",
    },
  },
  transaction_block1: {
    marginTop: "20px",
    padding: "0",
    listStyleType: "none",
    "& li": {
      borderBottom: "1px solid RGB(228, 226, 226)",
      fontSize: "14px",
      fontWeight: "400",
      color: "RGB(148, 148, 148)",
      display: "flex",
      justifyContent: "space-between",
      margin: "10px 0",
      paddingBottom: "10px",
      "& span": {
        fontSize: "14px",
        fontWeight: "400",
        color: "RGB(148, 148, 148)",
        textDecoration: "none",
      },
    },
  },
  bottom_button_block: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
  },
  button_main: {
    background: "none",
    color: "RGB(23, 72, 76)",
    fontSize: "14px",
    fontWeight: "700",
    borderRadius: "16px",
    border: "none",
    height: "50px",
    lineHeight: "66px",
  },

  //new css

  sendReportRoot: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  sendReportWrapper: {
    background: "#ffffff",
    borderTopLeftRadius: "51px",
    borderTopRightRadius: "51px",
    height: "76vh",
    padding: "20px",
  },
  sendReportHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "15px",
  },
  sendReportTopHeader: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "400",
  },
  sendReportHeaderText: {
    textAlign: "left",
    fontSize: "24px",
    fontWeight: "700",
  },
  recipientHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "15px",
  },
  recipientHeaderText: {
    color: "#313131",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "700",
    marginBottom: "15px",
  },
  transactionHeaderText: {
    color: "#313131",
    textAlign: "left",
    margin: "40px 20px 20px 20px",
    fontWeight: "700",
    fontSize: "18px",
  },

  recipientStyles: {
    color: "#313131",
    fontSize: "14px",
    fontWeight: "400",
    marginTop: "10px",
    textAlign: "left",
  },
  visibleInformation: {
    color: "#313131",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    marginLeft: "5px",
    lineHeight: "2",
  },
  reportConfirmedText: {
    color: "#313131",
    fontSize: "0.75rem",
  },
  reportMessageText: {
    color: "#949494",
    fontSize: "0.75rem",
    textAlign: "left",
    margin: "15px",
  },
  visibleInfoWrapper: {
    display: "flex",
  },
  reviewReportProofAmount: {
    color: "#313131",
    marginTop: "10px",
  },
  reqMinReport: {
    display: "flex",
    flexDirection: "column",
    height: "85px",
    backgroundColor: "#B8FBE2",
    borderRadius: "16px",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  reqMinReportWrapper: {
    display: "flex",
    padding: "10px 0 10px 0",
  },
  dividerText: {
    color: "#949494",
    textAlign: "left",
    fontSize: "12px",
    marginTop: "10px",
  },
  reqMinReportTitle: {
    color: "#949494",
    textAlign: "left",
    fontSize: "0.7rem",
    marginTop: "-10px",
  },
  optionalMessage: {
    color: "RGB(148, 148, 148)",
    textAlign: "left",
    fontSize: "12px",
    fontWeight: "400",
    margin: "10px 0 10px 0",
  },
  messageSubjectHeader: {
    color: "#313131",
    textAlign: "left",
    fontWeight: "600",
    marginBottom: "10px",
    marginTop: "10px",
  },
  messageSubjectWrapper: {
    // display: "flex"
  },
  fullNameEmailText: {
    color: "#313131",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "400",
  },
  emailInput: {},
  selectTemplateWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    marginTop: "15px",
  },
  selectTemplateHeaderText: {
    color: "#313131",
    textAlign: "left",
    fontSize: "18px",
    fontWeight: "700",
  },
  selectTemplateCard: {
    display: "flex",
    margin: "15px 0 15px 0",
    padding: "10px",
    borderRadius: "15px",
    width: "93%",
  },
  selectInformationWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "15px",
  },
  checkIcon: {
    width: "15px",
    height: "15px",
    marginTop: "5px",
    marginRight: "10px",
  },
  checkIconReport: {
    width: "15px",
    height: "15px",
    margin: "5px 0 5px 0",
  },
  checkIconReportConfirmed: {
    width: "40px",
    height: "40px",
    margin: "5px 0 5px 0",
  },
  reportConfirmedWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  optionalInfoCheckboxText: {
    color: "#32A19F",
  },
  optionalInfoCheckbox: {
    color: "#313131",
    "& span": {
      fontSize: "0.75rem",
    },
  },
  optionalInfoCheckboxColor: {
    color: "#32A19F",
  },
  nextStepButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  reviewButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  formControl: {
    width: "100%",
  },
  selectTemplateRadioLabel: {
    fontSize: "0.75rem",
    textAlign: "left",
  },
  radioRoot: {
    "&$checked": {
      color: "#32A19F",
    },
  },
  checked: {},
  selectMinimumCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    backgroundColor: "#d2e3f66b",
    width: "90%",
    borderRadius: "14px",
    boxShadow: "none",
    marginBottom: "15px",
  },
  selectMinimumInput: {
    width: "80%",
    "& label": {
      width: "130%",
      textAlign: "center",
    },
  },
  selectMinInputLabel: {
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  requiredMinHeader: {
    textAlign: "center",
  },
  minAmountInput: {
    textAlign: "center",
    "&:before": {
      borderBottom: "1px solid #E4E2E2",
    },
    "&:after": {
      borderBottom: "1px solid #E4E2E2",
    },
  },
  selectAccountsTopCardWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  totalSelectedHeader: {
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  accountsWrapper: {
    marginBottom: "10px",
    padding: "15px",
  },
  subAccountsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  linkedAccountsBankname: {
    fontSize: "14px",
    color: "#313131",
    fontWeight: "400",
  },
  linkedAccountsSubaccounts: {
    color: "#949494",
    fontSize: "0.65rem",
    margin: "10px 0",
  },
  linkedAccountsSubaccountsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "16px",
  },
  accountsNumber: {
    color: "black",
    fontSize: "11px",
    marginBottom: "10px",
    fontWeight: "400",
    textAlign: "left",
  },
  accountsDivider: {
    marginLeft: "-45px",
  },
  selectAccountsCard: {
    margin: "0px 0 20px 0",
    boxShadow: "0 6px 22px 0 RGBA(91, 91, 91, 0.25)",
    borderRadius: "20px",
  },
  bankTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  bankTitleSumWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  cancelButtonWrapper: {
    display: "flex",
    margin: "0px 0 20px 0",
    "& a ": {
      textDecoration: "none",
    },
  },
  cancelButtonRoot: {
    backgroundColor: "rgba(210,227,246,0.30)",
    borderRadius: "10px",
    height: "50px",
    width: "100px",
    "& span": {
      textTransform: "none",
    },
  },
  messageBodyRoot: {
    "& > div": {
      height: "150px",
      display: "flex",
      flexDirection: "column",
    },
  },
  messageBodyColor: {
    // "&$focused": {
    //     color: "red"
    // }
  },
  reviewButtonText: {
    color: "#32A19F",
    textTransform: "none",
  },
  sendReportButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  sendReportButton: {
    backgroundColor: "transparent",
    "& span": {
      textTransform: "none",
    },
  },
  selectTemplateBorder: {
    border: "2px solid #32A19F",
    borderRadius: "16px",
    width: "93%",
  },
  Proof_heading: {
    marginBottom: "20px",
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
      color: "#949494",
      borderBottom: "1px solid #949494",
      textAlign: "left",
      paddingBottom: "5px",
    },
  },
  address_main_block: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  address_block: {
    marginRight: "20px",
    "& img": {
      float: "left",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#313131",
      textAlign: "left",
      float: "left",
    },
  },
  bankAmount: {
    fonSize: "18px",
    fontWeight: "700",
    color: "#464646",
    marginBottom: "0px",
  },
  standard_adornment_amount: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "700",
    color: "#464646",
  },
  loadingDialog: {
    backgroundColor: "#000",
    opacity: ".7",
    color: 'white',
    paddingBottom: "20px"
  },
  spinner: {
      marginRight: '20px'
      
  },
  dialogText: {
      fontSize: '1.15rem'
  },
  dialogButton: {
    color: "white"
  },
  accountScroller: {
    maxHeight: "275px",
    overflowY: "scroll"
  }
}));

const mapStateToProps = (state) => ({
  token: state.token,
});

const SendReport = (props) => {
  const classes = withStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [token, setToken] = useState(null || props.token);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [error, setError] = useState(false);
  const [sendReportStep, setSendReportStep] = useState(0);
  const [templateBorder, setTemplateBorder] = useState(false);
  const [stepOneValid, setStepOneValid] = useState(false);
  const [stepTwoValid, setStepTwoValid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [myAccountData, setMyAccountData] = useState([]);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [message, setMessage] = useState();
  const [guestReport] = useState(props.guest || false);

  //newly created states for UI
  const [editStatus, seteditStatus] = useState(false);
  const [reportName, setreportName] = useState("");
  const [reportEmail, setreportEmail] = useState("");
  const [reportAddress, setreportAddress] = useState("");
  const [reportUnit, setreportUnit] = useState("");
  const [reportCity, setreportCity] = useState("");
  const [reportState, setreportState] = useState("");
  const [reportZip, setreportZip] = useState("");

  const { id, pin } = useParams();

  //TODO add this selectInfo to store
  const [selectInfo, setSelectInfo] = React.useState({
    address: "",
    phone: "",
    accountNumber: "",
    report_type: null,
    report_request_type: 2,
    message_body: null,
    message_subject: null,
    to_user: "",
    proof_amount: null,
    message_draft: 0,
    data: [],
    bankName: "",
    reportCode: ""
  });

  const {
    address,
    phone,
    accountNumber,
    report_type,
    report_request_type,
    message_body,
    message_subject,
    message_draft,
    to_user,
    data,
    proof_amount,
    bankName,
    reportCode
  } = selectInfo;

  const { dashboardData, dashboardDataLoading, reportSent, errorSendingReport, errorMsg, guestData, guestDataLoading, errorSendingGuestReport, guestError, guestReportSent } = useSelector((state) => state);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    if(!guestReport){
      fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/myaccount`, {
          method: "GET",
          headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`
          }
      })
      .then(res => {
        if(res.status === 401) {history.push("/");}
      })
        .then(res => res.json())
        .then(
          (result) => {
            setIsLoaded(true);
            setMyAccountData(result.accounts);
            console.log(result)
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
            // history.push("./");
          }
        )
    } else {
      if(guestData) {
        console.log(guestData);
        console.log('using guest data');
        formatGuestData(guestData);
      } else {
        console.log('no data');
      }
    }
  }, [guestData]);

  useEffect(() => {
    if(!guestReport) {
      if (selectInfo.to_user && getEmailErrorMessage(selectInfo.to_user) === "" && selectInfo.report_type !== null) {
        setStepOneValid(true);
      } else {
        setStepOneValid(false);
      }
    } else {
      // guest flow wont have email for recipient, just validate report type 
      if (selectInfo.report_type !== null) {
        setStepOneValid(true);
      } else {
        setStepOneValid(false);
      }
    }
  }, [selectInfo.to_user, selectInfo.report_type]);

  useEffect(() => {
    if(errorSendingReport) {
      setShowLoadingModal(false);
      setShowErrorModal(true);
    } 
  }, [errorSendingReport]);

  useEffect(() => {
    if (guestReportSent) {
      console.log('report sent success')
      window.location.href = '/communications';
    }
  }, [guestReportSent]);

  useEffect(() => {
    if(errorMsg) {
        console.log('setting message')
        setMessage(errorMsg)
    }
  }, [errorMsg]);

  useEffect(() => {
    if(errorSendingGuestReport) {
      setShowLoadingModal(false);
      setShowErrorModal(true);
    } 
  }, [errorSendingGuestReport]);

  useEffect(() => {
    if(guestError) {
      setShowLoadingModal(false);
      setShowErrorModal(true);
    } 
  }, [guestError]);
  

  useEffect(() => {
    if (!guestReport) {
      if (selectInfo.proof_amount && selectInfo.data.length > 0) {
        setStepTwoValid(true);
      } else {
        setStepTwoValid(false);
      }
    } else {
      if (selectInfo.proof_amount && totalSelected && (parseInt(totalSelected) > parseInt(selectInfo.proof_amount))) {
        setStepTwoValid(true);
      } else {
        setStepTwoValid(false);
      }
    }
  }, [selectInfo.proof_amount, selectInfo.data]);

  const totalSelected = useMemo(() => {
    if (selectedBanks.length > 0) {
      let totalSelectedValue = selectedBanks
        .map((acct) => acct.value)
        .reduce((a, b) => a + b);
      return totalSelectedValue;
    } else {
      return 0;
    }
  }, [selectedBanks]);

  const sendReport = useCallback(() => {
    setShowLoadingModal(true);
    if(guestReport) {
      let guestData = {
        ...selectInfo,
        public_token: token,
        request_id: id,
        request_pin: pin
      }
      dispatch(sendGuestReportData(guestData));
    } else {
      dispatch(sendReportData(selectInfo));
    }
    
  }, [dispatch, selectInfo]);

  const linkedAccounts = dashboardData ? dashboardData.linkedAccounts : ( guestData ? guestData.linkedAccounts : []);

  //TODO setup global handler for selectInfo
  const handleAddress = (event) => {
    if(event.target.checked) {
      setSelectInfo({ ...selectInfo, [event.target.name]: event.target.value });
    } else {
      setSelectInfo({ ...selectInfo, [event.target.name]: '' });
    }
  };

  const handlePhone = (event) => {
    if(event.target.checked) {
      setSelectInfo({ ...selectInfo, [event.target.name]: event.target.value });
    } else {
      setSelectInfo({ ...selectInfo, [event.target.name]: event.target.value });
    }
  };

  const handleAccountNumber = (event) => {
    if(event.target.checked) {
      setSelectInfo({ ...selectInfo, [event.target.name]: event.target.value });
    } else {
      setSelectInfo({ ...selectInfo, [event.target.name]: event.target.value });
    }
  };

  const handleReportType = (event) => {
    setSelectedReportType(event.target.value);
    setTemplateBorder(event.target.value);
    setSelectInfo({
      ...selectInfo,
      report_type: parseInt(event.target.value, 10),
    });
    setError(false);
  };

  const handleSelectedAccount = (event) => {
    let selectedAccount = event.target.value;
    setSelectedAccountId(selectedAccount);

    const userData = guestReport ? guestData : dashboardData;

    let selectedBank = userData.linkedAccounts.find((linkedAccount) =>
      linkedAccount.accounts.find((acct) => acct.account_id === selectedAccount)
    );
    console.log("selectedBank first", selectedBank);

    let selectedBankAccount = selectedBank.accounts.find(
      (acct) => acct.account_id === selectedAccount
    );
    console.log(selectedBankAccount);

    if (!selectedBanks.includes(selectedBankAccount)) {
      setSelectedBanks((selectedBanks) => [
        ...selectedBanks,
        selectedBankAccount,
      ]);
      const sendReportObj = {
        item_id: selectedBank.item_id,
        bankName: selectedBank.bankName,
        account_id: selectedAccount,
      };
      setSelectInfo((selectInfo) => ({
        ...selectInfo,
        data: [...selectInfo.data, sendReportObj],
      }));
    } else {
      setSelectInfo((selectInfo) => ({
        ...selectInfo,
        data: selectInfo.data.filter(
          (item) => item.account_id !== selectedBankAccount.account_id
        ),
      }));
      setSelectedBanks((selectedBanks) =>
        selectedBanks.filter(
          (item) => item.account_id !== selectedBankAccount.account_id
        )
      );
    }
    console.log("selectedBank second", selectedBanks);
    console.log("selectedBank last", selectInfo);
  };

  const handleMessageBody = (event) => {
    setSelectInfo({ ...selectInfo, message_body: event.target.value });
  };

  const handleMessageSubject = (event) => {
    setSelectInfo({ ...selectInfo, message_subject: event.target.value });
  };

  const handleToUser = (event) => {
    setSelectInfo({ ...selectInfo, to_user: event.target.value });
  };

  const handleReportCodeChange = (event) => {
    if(event.target.value == "") {
      setSelectInfo({ ...selectInfo, reportCode: event.target.value });
      return;  
    }
    if(isNaN(parseInt(event.target.value))) {
      return;  
    }
    if(event.target.value.length > 4) {
      setSelectInfo({ ...selectInfo, reportCode: event.target.value.substring(0,4) });
      return;  
    }
    setSelectInfo({ ...selectInfo, reportCode: event.target.value });
  };

  const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  const handleRequiredMin = (event) => {
    // let formattedNum = formatNumbers(event.target.value)
    setSelectInfo({ ...selectInfo, proof_amount: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(event);
    setSendReportStep((step) => step + 1);
    // console.log(selectInfo);
  };
  const ViewTransactions = (Nameofbank) => {
    setSendReportStep((step) => step + 1);

    setSelectInfo({ ...selectInfo, bankName: Nameofbank });
    console.log(selectInfo);
  };

  const sendReportBack = () => {
    setSendReportStep((step) => step - 1);
    console.log(sendReportStep);
  };

  const saveReportDraft = () => {
    setSelectInfo({ ...selectInfo, message_draft: 0 });
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const formatNumbers = (num) => {
    if (num % 1 !== 0) {
      let localeNum = num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
      return localeNum
    } else {
      return num.toLocaleString();
    }
  };

  const handleErrorModalClose= () => {
    setShowErrorModal(false);
    dispatch(clearSendReportError());
    dispatch(clearFetchGuestDataError());
  };

  const formatGuestData = (data) => {
    const names = [];
    const addresses = [];
    const phoneNums = [];
    const emails = [];

    let acctInfo = [];

    if(data && data.linkedAccounts) {
        data.linkedAccounts[0].accounts.forEach((acct)=>{
            acct.owners.forEach((owner)=>{
                owner.names.forEach((name)=>{
                    if(!names.includes(name)) {
                        names.push(name);
                    }
                });
                owner.addresses.forEach((address)=>{
                    if(!addresses.find(x => x.data.street === address.data.street)) {
                        addresses.push(address);
                    }
                });
                owner.phone_numbers.forEach((num)=>{
                    if(!phoneNums.find(x => x.data === num.data)) {
                        phoneNums.push(num.data);
                    }
                });
                owner.emails.forEach((email)=>{
                  if(!emails.find(x => x.data === email.data)) {
                      emails.push(email.data);
                  }
                });
                
            });
        });
    }

    acctInfo.push({
      name: names[0],
      email: emails[0],
      phone: phoneNums[0],
      address: `${addresses[0].data.street} ${addresses[0].data.city}, ${addresses[0].data.region} ${addresses[0].data.postal_code}`
    })
    setreportName(acctInfo[0].name);
    setreportEmail(acctInfo[0].email);
    setreportAddress(acctInfo[0].address.street);
    setreportCity(acctInfo[0].address.city);
    setreportUnit("");
    setreportState(acctInfo[0].address.region);
    setreportZip(acctInfo[0].address.postal_code);
    setSelectInfo({
      ...selectInfo,
      address: `${acctInfo[0].address.street} ${acctInfo[0].address.city}, ${acctInfo[0].address.region} ${acctInfo[0].address.postal_code}`,
      report_type: "1",
      proof_amount: data.proof_amount,
      to_user: acctInfo[0].email,
      from_user: acctInfo[0].email,
      message_subject: "guest response",
      message_body: "......."
    })

    setMyAccountData({
      accountInfo: acctInfo,
      linkedAssetValue: data.linkedAssetValue,
      linkedAccounts: data.linkedAccounts
    });
  };

  const getEmailErrorMessage= (to_user) => {
    if(to_user && !validateEmail(to_user)) {
      return "Enter a valid email address."
    } else if(myAccountData && myAccountData.accountInfo && myAccountData.accountInfo.length > 0 && myAccountData.accountInfo[0].email === to_user) {
      return "Enter a different email from your account email."
    }
    return '';
  };

  const renderFormStep = (step) => {
    if (step === 0) {
      return (
        <>
        {!guestReport &&
          <div className={classes.recipientHeader}>
             <Typography className={classes.recipientHeaderText}>
              Recipient
            </Typography>
            <form className={classes.emailInput} autoComplete="on">
              <TextField
                fullWidth={true}
                id="standard-basic"
                label="Email"
                value={to_user}
                onChange={handleToUser}
                InputLabelProps={{
                  style: { fontSize: "0.75rem" },
                }}
                error={getEmailErrorMessage(to_user) !== ""}
                helperText={getEmailErrorMessage(to_user)}
              />
            </form>
          </div>
        }
        <div className={classes.selectTemplateWrapper}>
          <Typography className={classes.selectTemplateHeaderText}>
            Select Template
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl
              component="fieldset"
              error={error}
              className={classes.formControl}
            >
              <RadioGroup
                aria-label="selectTemplate"
                name="selectTemplateRadioGroup"
                value={selectedReportType}
                onChange={handleReportType}
              >
                <Card
                  // elevation="5"
                  className={clsx(classes.selectTemplateCard, {
                    [classes.selectTemplateBorder]: templateBorder === "1",
                  })}
                >
                  <FormControlLabel
                    value="1"
                    control={
                      <Radio
                        classes={{
                          root: classes.radioRoot,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label={
                      <Typography
                        className={classes.selectTemplateRadioLabel}
                      >
                        Confirmation of Available Funds
                      </Typography>
                    }
                  />
                </Card>
                <Card
                  // elevation="5"
                  className={clsx(classes.selectTemplateCard, {
                    [classes.selectTemplateBorder]: templateBorder === "2",
                  })}
                >
                  <FormControlLabel
                    value="2"
                    control={
                      <Radio
                        classes={{
                          root: classes.radioRoot,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label={
                      <Typography
                        className={classes.selectTemplateRadioLabel}
                      >
                        Share Account Values Only
                      </Typography>
                    }
                  />
                </Card>
                {/* <Card
                  // elevation="5"
                  className={clsx(classes.selectTemplateCard, {
                    [classes.selectTemplateBorder]: templateBorder === "3",
                  })}
                >
                  <FormControlLabel
                    value="3"
                    control={
                      <Radio
                        classes={{
                          root: classes.radioRoot,
                          checked: classes.checked,
                        }}
                      />
                    }
                    label={
                      <Typography
                        className={classes.selectTemplateRadioLabel}
                      >
                        Share Account Values With Detailed Transactions
                      </Typography>
                    }
                  />
                </Card> */}
              </RadioGroup>
              {stepOneValid && !guestReport && myAccountData &&
                <Button type="submit" className={classes.nextStepButton}>
                  <img src="/icons/Button/Next_Color_On.svg" />
                </Button>
              }
              {stepOneValid && guestReport && guestData &&
                <Button type="submit" className={classes.nextStepButton}>
                  <img src="/icons/Button/Next_Color_On.svg" />
                </Button>
              }
            </FormControl>
          </form>
          <div className={classes.cancelButtonWrapper}>
            <Button
              classes={{
                root: classes.cancelButtonRoot,
              }}
              onClick={history.goBack}
            >
              Cancel
            </Button>
          </div>
          <Dialog
              open={showErrorModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle className={classes.loadingDialog}>ERROR</DialogTitle>
              <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                  <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                          {message ? message : "Error fetching data"}
                  </Typography>
              </DialogContent>
              <DialogActions className={classes.loadingDialog}>
                  <Button className={classes.dialogButton} onClick={() => handleErrorModalClose()}>
                  Okay
                  </Button>
              </DialogActions>
          </Dialog>
        </div>
        </>
      );
    } else if (step === 1) {
      return (
        <>
          <div className={classes.selectInformationWrapper}>
            <Typography className={classes.recipientHeaderText}>
              Select Information to Share
            </Typography>
            <div>
              <div className={classes.Proof_heading}>
                <p>Required</p>
              </div>

              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div className={classes.address_main_block}>
                  <div className={classes.address_block}>
                    <img
                      className={classes.checkIcon}
                      src="/icons/Checkmark.svg"
                    />
                    <Typography className={classes.fullNameEmailText}>
                      {myAccountData.accountInfo && myAccountData.accountInfo[0] ? myAccountData.accountInfo[0].name : ""}
                    </Typography>
                  </div>
                  <div className={classes.address_block}>
                    {myAccountData.accountInfo && myAccountData.accountInfo[0] && myAccountData.accountInfo[0].email && 
                    <img
                      className={classes.checkIcon}
                      src="/icons/Checkmark.svg"
                    />}
                    <Typography className={classes.fullNameEmailText}>
                      {myAccountData.accountInfo && myAccountData.accountInfo[0] ? myAccountData.accountInfo[0].email : ""}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.Proof_heading}>
                <p>Optional</p>
              </div>

              <div style={{ display: "flex" }}>
                <form className={classes.formControl} onSubmit={handleSubmit}>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.optionalInfoCheckboxText}
                            color="default"
                            checked={address !== ""}
                            onChange={handleAddress}
                            name="address"
                            value={myAccountData.accountInfo ? myAccountData.accountInfo[0].address : ""}
                          />
                        }
                        label={myAccountData.accountInfo ? myAccountData.accountInfo[0].address : ""}
                        className={classes.optionalInfoCheckbox}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.optionalInfoCheckboxText}
                            color="default"
                            checked={phone !== ""}
                            onChange={handlePhone}
                            name="phone"
                            value="123-456-7890" //user.phone
                            disabled
                          />
                        }
                        label="Phone"
                        className={classes.optionalInfoCheckbox}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={classes.optionalInfoCheckboxText}
                            color="default"
                            checked={accountNumber !== ""}
                            onChange={handleAccountNumber}
                            name="accountNumber"
                            value="12345789" //user.accountNumber
                            disabled
                          />
                        }
                        label="Account Number"
                        className={classes.optionalInfoCheckbox}
                      />
                    </FormGroup>
                    <Button type="submit" className={classes.nextStepButton}>
                      <img src="/icons/Button/Next_Color_On.svg" />
                    </Button>
                  </FormControl>
                </form>
              </div>
            </div>
            <div className={classes.cancelButtonWrapper}>
              <Button
                onClick={sendReportBack}
                classes={{
                  root: classes.cancelButtonRoot,
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <div className={classes.selectInformationWrapper}>
            <Typography className={classes.recipientHeaderText}>
              Select Accounts to Share
            </Typography>
            <div className={classes.selectAccountsTopCardWrapper}>
              <Card className={classes.selectMinimumCard}>
                <FormControl fullWidth className={classes.selectMinimumInput}>
                  <InputLabel
                    style={{textAlign: "center"}}
                    classes={{ focused: classes.selectMinInputLabel }}
                    htmlFor="standard-adornment-amount"
                    className={classes.standard_adornment_amount}
                  >
                    Required Minimum
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    classes={{
                      underline: classes.minAmountInput,
                    }}
                    className={classes.standard_adornment_amount}
                    value={proof_amount}
                    onChange={handleRequiredMin}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <Create style={{ color: "#32A19F" }} />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Card>
              <Card className={classes.selectMinimumCard}>
                <FormControl fullWidth className={classes.selectMinimumInput}>
                  <Typography className={classes.totalSelectedHeader}>
                    Total Selected
                  </Typography>
                  <Typography>{`$${formatNumbers(totalSelected)}`}</Typography>
                </FormControl>
              </Card>
            </div>
            <div>
              <form onSubmit={handleSubmit}>
                <FormControl
                  component="fieldset"
                  error={error}
                  className={classes.formControl}
                >
                  <div className={classes.accountScroller}>
                    {linkedAccounts.map((acct) => (
                      <Card className={classes.selectAccountsCard} key={acct.id}>
                        <div className={classes.accountsWrapper}>
                          <div className={classes.bankTitleSumWrapper}>
                            <div className={classes.bankTitleWrapper}>
                              <Typography
                                className={classes.linkedAccountsBankname}
                              >
                                {acct.bankName}
                              </Typography>
                              <Typography className={classes.accountsNumber}>
                                {acct.accounts.length + " Accounts"}
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                className={classes.bankAmount}
                              >{`+$${formatNumbers(
                                dashboardData ? dashboardData.linkedAssetValue : guestData.linkedAssetValue
                              )}`}</Typography>
                            </div>
                          </div>

                          <Divider className={classes.accountsDivider} />
                          <div className={classes.subAccountsWrapper}>
                            <FormGroup>
                              {acct.accounts.map((a) => (
                                <>
                                  <div
                                    className={
                                      classes.linkedAccountsSubaccountsWrapper
                                    }
                                  >
                                    <FormControlLabel
                                      value={a.account_id}
                                      control={
                                        <Checkbox
                                          checked={selectedBanks.find(
                                            (acct) =>
                                              acct.account_id === a.account_id
                                          )}
                                          onChange={handleSelectedAccount}
                                          className={
                                            classes.optionalInfoCheckboxText
                                          }
                                          color="default"
                                        />
                                      }
                                      label={
                                        <Typography
                                          className={
                                            classes.linkedAccountsSubaccounts
                                          }
                                        >
                                          {a.name}
                                        </Typography>
                                      }
                                      className={classes.optionalInfoCheckbox}
                                    />
                                    <Typography
                                      className={
                                        classes.linkedAccountsSubaccounts
                                      }
                                    >
                                      {`$${formatNumbers(a.value)}`}
                                    </Typography>
                                  </div>
                                  <Divider />
                                </>
                              ))}
                            </FormGroup>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </div>
                  {stepTwoValid === true ? (
                    <Button type="submit" className={classes.nextStepButton}>
                      <img src="/icons/Button/Next_Color_On.svg" />
                    </Button>
                  ) : null}
                </FormControl>
              </form>
              <div className={classes.cancelButtonWrapper}>
                <Button
                  onClick={sendReportBack}
                  classes={{
                    root: classes.cancelButtonRoot,
                  }}
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
        </>
      );
    } else if (step === 3) {
      return (
        <>
          <div className={classes.selectInformationWrapper}>
            <Typography className={classes.recipientHeaderText}>
              Include Additional Message?
            </Typography>
            <Typography className={classes.optionalMessage}>
              Optional
            </Typography>
            <form onSubmit={handleSubmit}>
              <div className={classes.messageSubjectWrapper}>
                <Typography className={classes.messageSubjectHeader}>
                  Title:
                </Typography>
                <TextField
                  multiline={false}
                  value={message_subject}
                  onChange={handleMessageSubject}
                  variant="outlined"
                  fullWidth={true}
                  placeholder="Give your message a title..."
                ></TextField>
              </div>

              <div>
                <Typography className={classes.messageSubjectHeader}>
                  Message:
                </Typography>
                <TextField
                  id="outlined-multiline-static"
                  multiline={true}
                  maxRows={8}
                  value={message_body}
                  onChange={handleMessageBody}
                  fullWidth={true}
                  variant="outlined"
                  autoFocus={false}
                  placeholder="Type your message here..."
                  classes={{
                    root: classes.messageBodyRoot,
                  }}
                ></TextField>
              </div>
              <div className={classes.reviewButtonWrapper}>
                <Button type="submit" className={classes.nextStepButton}>
                  <Typography className={classes.reviewButtonText}>
                    Review
                  </Typography>
                  <img src="/icons/Button/Next_Color_On.svg" />
                </Button>
              </div>
            </form>
            <div className={classes.cancelButtonWrapper}>
              <Button
                onClick={sendReportBack}
                classes={{
                  root: classes.cancelButtonRoot,
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </>
      );
    } else if (step === 4) {
      return (
        <>
          <div className={classes.selectInformationWrapper}>
            <Typography className={classes.recipientHeaderText}>
              Review Your Report
            </Typography>
            {!guestReport && <Typography className={classes.dividerText}>
              Recipient{" "}
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                style={{
                  fill: "rgb(50, 161, 159)",
                  width: "20px",
                  float: "right",
                }}
              >
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
              </svg>
            </Typography>}
            {!guestReport && <Divider />}
            {!guestReport && <Typography className={classes.recipientStyles}>
              {selectInfo.to_user}
            </Typography>}
            <Typography className={classes.dividerText}>
              Visible Information{" "}
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                style={{
                  fill: "rgb(50, 161, 159)",
                  width: "20px",
                  float: "right",
                }}
              >
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
              </svg>
            </Typography>
            <Divider />
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <div className={classes.visibleInfoWrapper}>
                  <img
                    className={classes.checkIconReport}
                    src="/icons/Checkmark.svg"
                  />
                  <Typography className={classes.visibleInformation}>
                    {myAccountData.accountInfo[0].name || ""}
                  </Typography>
                </div>
                {selectInfo.address.length > 0 ? (
                  <div className={classes.visibleInfoWrapper}>
                    <img
                      className={classes.checkIconReport}
                      src="/icons/Checkmark.svg"
                    />
                    <Typography className={classes.visibleInformation}>
                      {selectInfo.address}
                    </Typography>
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <div className={classes.visibleInfoWrapper}>
                  <img
                    className={classes.checkIconReport}
                    src="/icons/Checkmark.svg"
                  />
                  <Typography className={classes.visibleInformation}>
                    {myAccountData.accountInfo[0].email || ""}
                  </Typography>
                </div>
                {selectInfo.phone.length > 0 ? (
                  <div className={classes.visibleInfoWrapper}>
                    <img
                      className={classes.checkIconReport}
                      src="/icons/Checkmark.svg"
                    />
                    <Typography className={classes.visibleInformation}>
                      {selectInfo.phone}
                    </Typography>
                  </div>
                ) : null}
                {selectInfo.report_type === 1 ? (
                  <div className={classes.visibleInfoWrapper}>
                    <img
                      className={classes.checkIconReport}
                      src="/icons/Checkmark.svg"
                    />
                    <Typography className={classes.visibleInformation}>
                      Confirmation of Funds
                    </Typography>
                  </div>
                ) : null}
                {selectInfo.report_type === 2 ? (
                  <div className={classes.visibleInfoWrapper}>
                    <img
                      className={classes.checkIconReport}
                      src="/icons/Checkmark.svg"
                    />
                    <Typography className={classes.visibleInformation}>
                      Account Values Only
                    </Typography>
                  </div>
                ) : null}
                {selectInfo.report_type === 3 ? (
                  <div className={classes.visibleInfoWrapper}>
                    <img
                      className={classes.checkIconReport}
                      src="/icons/Checkmark.svg"
                    />
                    <Typography className={classes.visibleInformation}>
                      Account Values with Details
                    </Typography>
                  </div>
                ) : null}
              </div>
            </div>
            <Typography className={classes.dividerText}>
              Proof of Funds{" "}
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                style={{
                  fill: "rgb(50, 161, 159)",
                  width: "20px",
                  float: "right",
                }}
              >
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
              </svg>
            </Typography>
            <Divider />
            <div className={classes.reqMinReportWrapper}>
              <div className={classes.reqMinReport}>
                <Typography className={classes.reqMinReportTitle}>
                  Required Minimum
                </Typography>

                {selectInfo.proof_amount ? (
                  <Typography
                    className={classes.reviewReportProofAmount}
                  >{`$${formatNumbers(selectInfo.proof_amount)}`}</Typography>
                ) : null}
              </div>
              <div className={classes.reportConfirmedWrapper}>
                <img
                  className={classes.checkIconReportConfirmed}
                  src="/icons/Button/Check_On.svg"
                />
                <Typography className={classes.reportConfirmedText}>
                  Confirmed
                </Typography>
              </div>
            </div>
            <Typography className={classes.dividerText}>
              Account Visibility{" "}
              <svg
                className="MuiSvgIcon-root"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                style={{
                  fill: "rgb(50, 161, 159)",
                  width: "20px",
                  float: "right",
                }}
              >
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
              </svg>
            </Typography>

            {selectInfo.report_type === 2 ? (
              <ul className={classes.transaction_block}>
                {linkedAccounts.map((item) => {
                  return (
                    <li>
                      <p>{item.bankName}</p>{" "}
                      <p> {dashboardData.linkedAssetValue}</p>
                    </li>
                  );
                })}
              </ul>
            ) : null}

            {selectInfo.report_type === 3 ? (
              <ul className={classes.transaction_block}>
                {linkedAccounts.map((item) => {
                  return (
                    <li>
                      {item.bankName}{" "}
                      <Link onClick={() => ViewTransactions(item.bankName)}>
                        View Transactions{" "}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            ) : null}

            {selectInfo.message_body ? (
              <>
                <Typography className={classes.dividerText}>
                  Message{" "}
                  <svg
                    className="MuiSvgIcon-root"
                    focusable="false"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    style={{
                      fill: "rgb(50, 161, 159)",
                      width: "20px",
                      float: "right",
                    }}
                  >
                    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                  </svg>
                </Typography>
                <Divider />
                <Typography className={classes.reportMessageText}>
                  {selectInfo.message_subject}
                </Typography>
                <Typography className={classes.reportMessageText}>
                  {selectInfo.message_body}
                </Typography>
              </>
            ) : null}
            <div className={classes.sendReportButtonWrapper}>
              <Button
                onClick={() => sendReport()}
                className={classes.nextStepButton}
              >
                <Typography className={classes.reviewButtonText}>
                  Send
                </Typography>
                <img src="/icons/Button/Mail_On.svg" />
              </Button>
            </div>
            <div className={classes.cancelButtonWrapper}>
              <Button
                onClick={saveReportDraft}
                classes={{
                  root: classes.cancelButtonRoot,
                }}
              >
                Save Draft
              </Button>
            </div>

            {/* <div className={classes.cancelButtonWrapper}>
              <Button
                onClick={sendReportBack}
                classes={{
                  root: classes.cancelButtonRoot,
                }}
              >
                Back
              </Button>
            </div> */}
          </div>
          <Dialog
              open={showLoadingModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                  <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                      <CircularProgress className={classes.spinner} color="inherit" />
                        Sending Report...
                  </Typography>
              </DialogContent>
          </Dialog>
          <Dialog
              open={showErrorModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle className={classes.loadingDialog}>ERROR</DialogTitle>
              <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
                  <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                          {message ? message : "Failed to send report. Please try again."}
                  </Typography>
              </DialogContent>
              <DialogActions className={classes.loadingDialog}>
                  <Button className={classes.dialogButton} onClick={() => handleErrorModalClose()}>
                  Okay
                  </Button>
              </DialogActions>
          </Dialog>
        </>
      );
    } else if (step === 5) {
      return (
        <div>
          <Typography className={classes.transactionHeaderText}>
            Transactions
          </Typography>
          <div className={classes.transaction_details}>
            <h2>{bankName}</h2>
            <p>#09999281745</p>

            {Transactions.transactions.map((line) => {
              return (
                <ul className={classes.transaction_block1}>
                  <li>
                    {line.name} <span>{line.amount}</span>
                  </li>
                </ul>
              );
            })}
          </div>
          <div className={classes.bottom_button_block}>
            <button className={classes.button_main} onClick={sendReportBack}>
              <img
                src="/icons/Button/Next_Color_On.svg"
                style={{ transform: "rotate(180deg)", float: "left" }}
              />
              Return
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={classes.sendReportRoot}>
      <Layout pageTitle={props.title} />
      <div className={classes.sendReportHeaderWrapper}>
        <Typography className={classes.sendReportTopHeader}>
          Create Your Custom Report
        </Typography>
        <Typography className={classes.sendReportHeaderText}>
          Send Proof of Funds
        </Typography>
      </div>
      {reportSent && 
        <div>
          <Typography className={classes.sendReportHeaderText}>
            Report sent!
          </Typography>
        </div>
      }
      <div className={classes.sendReportWrapper}>
        {renderFormStep(sendReportStep)}
      </div>
      <Dialog
            open={(guestReport && !guestData && guestDataLoading && !guestError) || false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                  <CircularProgress className={classes.spinner} color="inherit" />
                    Loading your data... This could take a minute.
              </Typography>
          </DialogContent>
      </Dialog>
    </div>
  );
};

export default connect(mapStateToProps)(SendReport);