import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import { useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import clsx from "clsx";
import { Link } from "react-router-dom";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import AssetCommunications from "../../data/communications.json";

import Icon from "@material-ui/core/Icon";

const Accordion = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {},
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "#fff",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",

    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      backgroundColor: "RGBA(210, 227, 246, 0.3)",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const withStyles1 = makeStyles(() => ({
  communicationsRoot: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  communicationsWrapper: {
    background: "#ffffff",
    borderTopLeftRadius: "51px",
    borderTopRightRadius: "51px",
    height: "100vh",
    marginBottom: "30px",
  },
  communicationsHeader: {
    color: "#313131",
    textAlign: "left",
    fontWeight: "700",
    fontSize: "18px",
  },
  communicationsSubHeader: {
    fontSize: "0.75rem",
    color: "#313131",
    textAlign: "left",
  },
  communicationsHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "40px",
    marginLeft: "30px",
    marginBottom: "20px",
  },
  messagesRoot: {
    "& > *": {
      pointerEvents: "none",
    },
  },
  messagesWrapper: {
    color: "#313131",
    display: "flex",
    justifyContent: "space-between",
    height: "40px",
    paddingTop: "15px",
    paddingBottom: "15px",
    width: "80%",
    margin: "auto",
    "&  p": {
      fontSize: "0.75rem",
      textAlign: "left",
    },
    "&:selected:hover": {
      border: "1px solid red",
    },
  },
  messagesWrapperSelected: {
    background: "rgba(210,227,246,0.30)",
  },
  messagesSubWrapper: {
    display: "flex",
  },
  messagesHexIcon: {
    marginRight: "15px",
    position: "relative",
    float: "left",
  },
  messagesSubjectLine: {
    color: "#949494",
    fontSize: "0.65rem !important",
  },
  messagesDivider: {
    width: "80%",
    margin: "auto",
  },
  messagesTime: {
    color: "#949494",
    fontSize: "0.65rem !important",
    textAlign: "right !important",
  },
  communicationsSendCreateButtonsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px 20px 20px",
  },
  communicationsSendCreateButtons: {
    display: "flex",

    background: "rgba(234,234,234,0.25)",

    borderRadius: "12px",
    padding: "10px",
  },
  communicationsSendCreateButtonsLink: {
    display: "flex",
    marginLeft: "5px",
    marginRight: "5px",
    borderRadius: "12px",
    textDecoration: "none",
    color: "white",
  },
  sendButton: {
    width: "25px",
    marginRight: "5px",
  },
  selectedMessageSubject: {
    textAlign: "left",
    margin: "20px",
  },
  selectedMessageBody: {
    textAlign: "left",
    margin: "20px",
    fontSize: "0.75rem",
  },
  selectedMessageReportButton: {
    background: "rgba(210,227,246,0.30)",
    fontSize: "0.75rem",
    textTransform: "none",
    borderRadius: "14px",
  },
  accordion_heading: {
    position: "relative",
  },
  accordion_heading_p: {
    position: "absolute",
    top: "0",
    width: "45px",
    display: "flex",
    alignItems: "center",
    height: "46px",
    justifyContent: "center",
    margin: "0",
    fontSize: "14px",
    fontWeight: "400",
    color: "RGB(66, 66, 66)",
  },
  accordion_content: {
    width: "100%",
  },
  accordion_content_h2: {
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "600",
    color: "#313131",
    marginTop: "0",
    marginBottom: "0px",
  },
  accordion_content_p: {
    fontSize: "12px",
    color: "RGB(148, 148, 148)",
    fontWeight: "400",
    textAlign: "left",
    marginTop: "0",
    marginBottom: "0",
  },
  accordion_content_right: {
    width: "100%",
    marginRight: "15px",
  },
  accordion_content_right_h2: {
    fontSize: "14px",
    fontWeight: "600",
    color: "#313131",
    marginTop: "0",
    marginBottom: "0px",
    textAlign: "right",
  },
  accordion_content_right_p: {
    fontSize: "12px",
    color: "RGB(148, 148, 148)",
    fontWeight: "400",
    marginTop: "0",
    marginBottom: "0",
    textAlign: "right",
  },
  accordion_expand_block_h2: {
    fontSize: "18px",
    fontWeight: "700",
    color: "RGB(49, 49, 49)",
    margin: "0",
    textAlign: "left",
  },
  accordion_expand_block_p: {
    color: "RGB(49, 49, 49)",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "left",
    marginBottom: "20px",
  },
  accordion_expand_block: {
    paddingLeft: "12%",
    paddingRight: "12%"
  },
  report_block: {
    backgroundColor: "RGBA(210, 227, 246, 0.3)",
    borderRadius: "14px",
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  check_circle: {
    width: "31px",
    height: "31px",
    float: "left",
    backgroundColor: "RGB(50, 161, 159)",
    borderRadius: "50%",
    boxShadow: "0 6px 22px 0 RGBA(126, 126, 126, 0.5)",
    minWidth: "31px",
  },
  check_circle_grey: {
    width: "31px",
    height: "31px",
    float: "left",
    backgroundColor: "RGBA(210, 227, 246, 0.3)",
    borderRadius: "50%",
    minWidth: "31px",
    visibility: "hidden"
  },
  check_circle_svg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "70%",
    margin: "0 auto",
  },
  report_content: {
    textDecoration: "none",
  },
  report_content_h2: {
    fontSize: "14px",
    fontWeight: "700",
    color: "#313131",
    textAlign: "left",
    margin: "0",
    textDecoration: "none",
  },
  report_content_p: {
    color: "#32a19f",
    fontSize: "12px",
    fontWeight: "400",
    textAlign: "left",
    margin: "0",
    textDecoration: "none",
  },
  wrong_content_p: {
    color: "RGB(191, 41, 41) !important",
  },
  wrong_circle: {
    backgroundColor: "RGB(191, 41, 41)",
  },
  pending_content_p: {
    color: "RGB(213, 130, 25) !important",
  },
  pending_circle: {
    backgroundColor: "RGB(213, 130, 25)",
  },
  myStyles: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  }
}));

const mapStateToProps = (state) => ({
  token: state.token,
});

const Communications = (props) => {
  const [expanded, setExpanded] = React.useState("");

  const classes = withStyles1();
  const history = useHistory();
  const [communicationsData, setCommunicationsData] = useState([]);
  const [token, setToken] = useState(null || props.token);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  const showSelectedMessage = (event) => {
    event.preventDefault();
    let selectedMsgId = parseInt(event.target.id);
    setSelectedMessageId(selectedMsgId);
    let selectedMsg = communicationsData.communications.find(
      (msg) => msg.id === selectedMsgId
    );
    setSelectedMessage(selectedMsg);
  };

  const dateFilter = (newDate) => {
    var options = { year: "numeric", month: "numeric", day: "numeric" };
    let date = new Date(newDate).toLocaleDateString([], options);

    return date;
  };

  const timeFilter = (newDate) => {
    var options = { hour: "numeric", hour12: true, minute: "numeric" };
    let time = new Date(newDate).toLocaleTimeString([], options);

    return time;
  };

  const handleChange = (panel, msg) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    event.preventDefault();
    let selectedMsgId = parseInt(msg);
    setSelectedMessageId(selectedMsgId);
    let selectedMsg = communicationsData.communications.find(
      (msg) => msg.id === selectedMsgId
    );

    setTimeout(() => {
      setSelectedMessage(selectedMsg);
    }, 150);
  };
  if (!props.token || token === null) {
    history.push("./");
  }

  const getInitials = (getname) => {
    var matches = getname.match(/\b(\w)/g); // ['J','S','O','N']
    var acronym = matches.join("");
    return acronym[0].toUpperCase() + acronym[1].toUpperCase();
    // const fullName = getname.split(".");

    // const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    // return initials.toUpperCase();
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/communications`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }).then(res => {
      if(res.status === 401) {history.push("/");}
    })
      // .then(response => {
      //     if(response.status !== 200){
      //         window.location.href('/')
      //         throw new Error(response.status)
      //     } else {
      //         res => res.json()
      //     }
      // })
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setCommunicationsData(result);
          console.log(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
          //temporary front end fix if token expired. TODO - have API send response that token is expired, then redirect
          history.push("./");
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className={classes.communicationsRoot}>
        <Layout pageTitle={props.title} />
        <div className={classes.communicationsSendCreateButtonsWrapper}>
          <Link
            className={classes.communicationsSendCreateButtonsLink}
            to="/sendreport"
          >
            <div className={classes.communicationsSendCreateButtons}>
              <img
                className={classes.sendButton}
                src="/icons/Icon_Send_White.svg"
              />
              <Typography
                style={{ margin: "auto", fontSize: "16px", fontWeight: "600" }}
              >
                Send Report
              </Typography>
            </div>
          </Link>
          <Link
            className={classes.communicationsSendCreateButtonsLink}
            to="/requestreport"
          >
            <div className={classes.communicationsSendCreateButtons}>
              <img
                className={classes.sendButton}
                src="/icons/Icon_Request_White.svg"
              />
              <Typography
                style={{ margin: "auto", fontSize: "16px", fontWeight: "600" }}
              >
                Create Request
              </Typography>
            </div>
          </Link>
          
        </div>
        <div className={classes.communicationsWrapper}>
          <div className={classes.communicationsHeaderWrapper}>
            <Typography className={classes.communicationsHeader}>
              Communications
            </Typography>
          </div>
          <div>
            
          </div>
          {communicationsData.communications
            ? communicationsData.communications.map((msg, idx) => (
                <React.Fragment key={msg.id}>
                  <Accordion
                    square
                    expanded={expanded === `panel${msg.id}`}
                    onChange={handleChange(`panel${msg.id}`, msg.id)}
                    id={msg.id}
                  >
                    <AccordionSummary
                      aria-controls={`panel${msg.id}d-content`}
                      id={`panel${msg.id}d-header`}
                    >
                        <div className={classes.myStyles}>
                          <div className={classes.accordion_heading}>
                            <img
                              className={classes.messagesHexIcon}
                              src="/icons/Light_Hexagon.svg"
                            />
                            <p className={classes.accordion_heading_p}>{getInitials(msg.from_user)}</p>
                          </div>
                          <div className={classes.accordion_content}>
                            <h2 className={classes.accordion_content_h2}>{msg.from_user}</h2>
                            <p className={classes.accordion_content_p}>{msg.message_subject}</p>
                          </div>
                        </div>
                        <div>
                          <div className={classes.accordion_content_right}>
                            <h2 className={classes.accordion_content_right_h2}>{dateFilter(msg.message_date)}</h2>
                            {/* <p className={classes.accordion_content_right_p}>{timeFilter(msg.message_date)}</p> */}
                          </div>
                        </div>
                    </AccordionSummary>
                    {selectedMessage !== null ? (
                      <AccordionDetails className={classes.accordion_expand_block}>
                        <div>
                          <div >
                            <h2 className={classes.accordion_expand_block_h2}>Incoming Client Asset Report</h2>
                            <p className={classes.accordion_expand_block_p}>
                              {selectedMessage.message_subject} <br /> <br />
                              {selectedMessage.message_body}
                            </p>
                          </div>
                          {selectedMessage.report.fund_status &&
                            <Link to={`/prooffund/${selectedMessage.id}`} className={classes.report_content}>
                              <div className={classes.report_block}>

                                {selectedMessage.report.fund_status && selectedMessage.report.fund_status == true &&
                                  <React.Fragment>
                                    <div className={classes.check_circle}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="#fff"
                                        className={classes.check_circle_svg}
                                      >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                                      </svg>
                                    </div>
                                    <div  className={classes.report_content}>
                                      <h2 className={classes.report_content_h2}>{`Asset Report`}</h2>
                                      <p className={classes.report_content_p}>Available Funds Confirmation</p>
                                    </div>
                                  </React.Fragment>
                                }

                                {selectedMessage.report.fund_status && selectedMessage.report.fund_status == false &&
                                  <React.Fragment>
                                    <div className={classes.check_circle}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="#fff"
                                        className={classes.check_circle_svg}
                                      >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                                      </svg>
                                    </div>
                                    <div  className={classes.report_content}>
                                      <h2 className={classes.report_content_h2}>{`Asset Report`}</h2>
                                      <p className={classes.report_content_p}>Available Funds Confirmation</p>
                                    </div>
                                  </React.Fragment>
                                }

                                {selectedMessage.report && selectedMessage.report.fund_status == 'pending' &&
                                  <React.Fragment>
                                        <div className={`${classes.check_circle} ${classes.pending_circle}`}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="#fff"
                                            className={classes.check_circle_svg}
                                          >
                                            <g>
                                              <rect
                                                fill="none"
                                                height="24"
                                                width="24"
                                              />
                                            </g>
                                            <g>
                                              <g>
                                                <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,20c-4.42,0-8-3.58-8-8 c0-4.42,3.58-8,8-8s8,3.58,8,8C20,16.42,16.42,20,12,20z" />
                                                <circle cx="7" cy="12" r="1.5" />
                                                <circle cx="12" cy="12" r="1.5" />
                                                <circle cx="17" cy="12" r="1.5" />
                                              </g>
                                            </g>
                                          </svg>
                                        </div>
                                        <div>
                                          <h2 className={classes.report_content_h2}>{`${selectedMessage.name}'s Asset Report`}</h2>
                                          <p className={`${classes.report_content_p} ${classes.pending_content_p}`}>Report Pending</p>
                                        </div>
                                      </React.Fragment>
                                }

                                <div style={{ display: "flex" }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    fill="#000000"
                                  >
                                    <g>
                                      <path d="M0,0h24v24H0V0z" fill="none" />
                                    </g>
                                    <g>
                                      <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </Link>
                          }
                          {!selectedMessage.report.fund_status &&
                            <div className={classes.report_content}>
                              <div className={classes.report_block}>

                                  <React.Fragment>
                                    <div className={classes.check_circle_grey}>
                                      {/* <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24px"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        fill="#fff"
                                        className={classes.check_circle_svg}
                                      >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                                      </svg> */}
                                    </div>
                                    <div  className={classes.report_content}>
                                      <h2 className={classes.report_content_h2}>{`No Asset Report available`}</h2>
                                    </div>
                                  </React.Fragment>

                                  <div style={{ display: "flex" }}>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      fill="RGBA(210, 227, 246, 0.3)"
                                    >
                                      <g>
                                        <path d="M0,0h24v24H0V0z" fill="none" />
                                      </g>
                                      <g>
                                        <polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12" />
                                      </g>
                                    </svg>
                                  </div>
                              </div>
                            </div>
                          }
                        </div>
                      </AccordionDetails>
                    ) : null}
                  </Accordion>

                  <div className="open_block"></div>
                </React.Fragment>
              ))
            : null}
        </div>

        <div>{props.children}</div>
      </div>
    );
  }
};
export default connect(mapStateToProps)(Communications);
