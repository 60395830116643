import React, { useState, useEffect, useCallback, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Layout from "./Layout";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import FilledInput from "@material-ui/core/FilledInput";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Create from "@material-ui/icons/Create";
import { fetchDashboardData } from "../actions/addLinkedAccounts";
import { sendReportData } from "../actions/addLinkedAccounts";
import clsx from "clsx";
import MyAccount from "../data/myaccount.json";

const withStyles = makeStyles(() => ({
  //newly added css
  proof_report_block: {
    "& h2": {
      fontSize: "18px",
      fontWeight: "700",
      color: "#313131",
      marginTop: "0",
      textAlign: "left",
    },
  },
  Proof_heading: {
    marginBottom: "20px",
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
      color: "#949494",
      borderBottom: "1px solid #949494",
      textAlign: "left",
      paddingBottom: "5px",
    },
  },
  address_main_block: {
    display: "flex",
    justifyContent: "space-between",
  },
  address_block: {
    "& h2": {
      fontSize: "14px !important",
      fontWeight: "400 !important",
      color: "#313131",
      textAlign: "left",
      marginBottom: "0",
    },
    "& h3": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#313131",
      textAlign: "left",
      marginTop: "15px",
    },
  },
  amount_main_block: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  required_block: {
    backgroundColor: "RGBA(184, 251, 226, 0.7)",
    borderRadius: "14px",
    padding: "15px",
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
      color: "#949494",
      textAlign: "center",
      marginTop: "0",
    },
    "& h2": {
      color: "#464646",
      fontSize: "18px",
      fontWeight: "700",
      marginBottom: "0",
    },
  },
  confirmed_block: {
    display: "flex",
    alignItems: "center",
  },
  check_circle: {
    width: "31px",
    height: "31px",
    float: "left",
    backgroundColor: "RGB(50, 161, 159)",
    borderRadius: "50%",
    boxShadow: "0 6px 22px 0 RGBA(126, 126, 126, 0.5)",
    minWidth: "31px",
    "& svg": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "70%",
      margin: "0 auto",
    },
  },
  confirmed_block_p: {
    color: "#313131",
    fontSize: "14px",
    fontWeight: "400",
    float: "left",
    marginLeft: "10px",
  },
  message_main_block: {
    "& p": {
      fontSize: "12px",
      color: "#949494",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "20px",
    },
  },
  bottom_button_block: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
  },
  button_main: {
    backgroundColor: "RGBA(210, 227, 246, 0.3)",
    color: "RGB(23, 72, 76)",
    fontSize: "14px",
    fontWeight: "700",
    borderRadius: "16px",
    border: "1px solid RGBA(210, 227, 246, 0.3)",
    width: "126px",
    height: "50px",
  },
  last_button: {
    border: "none",
    background: "none",
    color: "RGB(50, 161, 159)",
    fontSize: "16px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    "& div": {
      marginLeft: "10px",
      width: "45px",
      height: "45px",
      minWidth: "45px",
      float: "left",
      backgroundColor: "RGB(50, 161, 159)",
      borderRadius: "50%",
      boxShadow: "0 6px 22px 0 RGBA(126, 126, 126, 0.5)",
      "& svg": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "50%",
        margin: "0 auto",
      },
    },
  },

  //newly added css

  sendReportRoot: {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  sendReportWrapper: {
    background: "#ffffff",
    borderTopLeftRadius: "51px",
    borderTopRightRadius: "51px",
    height: "90vh",
  },
  sendReportHeaderWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "15px",
  },
  sendReportTopHeader: {
    textAlign: "left",
    fontSize: "0.75rem",
  },
  sendReportHeaderText: {
    textAlign: "left",
    fontSize: "1.5rem",
  },
  recipientHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "83%",
    marginTop: "40px",
    marginLeft: "30px",
    marginBottom: "20px",
  },
  recipientHeaderText: {
    color: "#313131",
    textAlign: "left",
  },
  recipientStyles: {
    color: "gray",
    fontSize: "0.75rem",
    margin: "10px 10px 0px 20px",
    textAlign: "left",
  },
  visibleInformation: {
    color: "gray",
    fontSize: "0.75rem",
    textAlign: "left",
    marginLeft: "5px",
    lineHeight: "2",
  },
  reportConfirmedText: {
    color: "#313131",
    fontSize: "0.75rem",
  },
  reportMessageText: {
    color: "#949494",
    fontSize: "0.75rem",
    textAlign: "left",
    margin: "15px",
  },
  visibleInfoWrapper: {
    display: "flex",
  },
  reviewReportProofAmount: {
    color: "#313131",
    marginTop: "10px",
  },
  reqMinReport: {
    display: "flex",
    flexDirection: "column",
    height: "85px",
    backgroundColor: "#B8FBE2",
    borderRadius: "16px",
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  reqMinReportWrapper: {
    display: "flex",
    padding: "10px 0 10px 0",
  },
  dividerText: {
    color: "#313131",
    textAlign: "left",
    fontSize: "0.6rem",
    marginTop: "10px",
  },
  reqMinReportTitle: {
    color: "#949494",
    textAlign: "left",
    fontSize: "0.7rem",
    marginTop: "-10px",
  },
  optionalMessage: {
    color: "rgba(0, 0, 0, 0.54)",
    textAlign: "left",
    fontSize: "0.6rem",
    margin: "10px 0 10px 0",
  },
  messageSubjectHeader: {
    color: "#313131",
    textAlign: "left",
  },
  messageSubjectWrapper: {
    // display: "flex"
  },
  fullNameEmailText: {
    color: "#313131",
    textAlign: "left",
    marginRight: "50px",
    fontSize: "0.75rem",
    margin: "5px",
  },
  emailInput: {},
  selectTemplateWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "80%",
    marginTop: "40px",
    marginLeft: "30px",
    marginBottom: "20px",
  },
  selectTemplateHeaderText: {
    color: "#313131",
    textAlign: "left",
  },
  selectTemplateCard: {
    display: "flex",
    margin: "15px 0 15px 0",
    padding: "10px",
    borderRadius: "15px",
  },
  selectInformationWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "80%",
    marginTop: "40px",
    marginLeft: "30px",
    marginBottom: "20px",
  },
  checkIcon: {
    width: "15px",
    height: "15px",
    margin: "5px",
  },
  checkIconReport: {
    width: "15px",
    height: "15px",
    margin: "5px 0 5px 0",
  },
  checkIconReportConfirmed: {
    width: "40px",
    height: "40px",
    margin: "5px 0 5px 0",
  },
  reportConfirmedWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
  },
  optionalInfoCheckboxText: {
    color: "#32A19F",
  },
  optionalInfoCheckbox: {
    color: "#313131",
    "& span": {
      fontSize: "0.75rem",
    },
  },
  optionalInfoCheckboxColor: {
    color: "#32A19F",
  },
  nextStepButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  reviewButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  formControl: {
    width: "100%",
  },
  selectTemplateRadioLabel: {
    fontSize: "0.75rem",
    textAlign: "left",
  },
  radioRoot: {
    "&$checked": {
      color: "#32A19F",
    },
  },
  checked: {},
  selectMinimumCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0px 10px 0px",
    padding: "15px",
    backgroundColor: "#d2e3f66b",
  },
  selectMinimumInput: {
    width: "80%",
    "& label": {
      width: "130%",
      textAlign: "center",
    },
  },
  selectMinInputLabel: {
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  requiredMinHeader: {
    textAlign: "center",
  },
  minAmountInput: {
    "&:before": {
      borderBottom: "1px solid #E4E2E2",
    },
    "&:after": {
      borderBottom: "1px solid #E4E2E2",
    },
  },
  selectAccountsTopCardWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  totalSelectedHeader: {
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  accountsWrapper: {
    marginBottom: "10px",
    marginLeft: "45px",
  },
  subAccountsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  linkedAccountsBankname: {
    fontSize: "0.75rem",
    color: "#313131",
  },
  linkedAccountsSubaccounts: {
    color: "#949494",
    fontSize: "0.65rem",
    margin: "10px 0",
  },
  linkedAccountsSubaccountsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "16px",
  },
  accountsNumber: {
    color: "black",
    fontSize: "0.65rem",
    marginBottom: "10px",
    textAlign: "left",
  },
  accountsDivider: {
    marginLeft: "-45px",
  },
  selectAccountsCard: {
    margin: "20px 0 20px 0",
  },
  bankTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  bankTitleSumWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  cancelButtonWrapper: {
    display: "flex",
    margin: "20px 0 20px 0",
    "& a ": {
      textDecoration: "none",
    },
  },
  cancelButtonRoot: {
    backgroundColor: "rgba(210,227,246,0.30)",
    borderRadius: "10px",
    height: "50px",
    width: "100px",
    "& span": {
      textTransform: "none",
    },
  },
  messageBodyRoot: {
    "& > div": {
      height: "150px",
      display: "flex",
      flexDirection: "column",
    },
  },
  messageBodyColor: {
    // "&$focused": {
    //     color: "red"
    // }
  },
  reviewButtonText: {
    color: "#32A19F",
    textTransform: "none",
  },
  sendReportButtonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
  },
  sendReportButton: {
    backgroundColor: "transparent",
    "& span": {
      textTransform: "none",
    },
  },
  selectTemplateBorder: {
    border: "2px solid #32A19F",
    borderRadius: "16px",
  },
  center: {
    textAlign: "center !important"
  },
}));

const mapStateToProps = (state) => ({
  token: state.token,
});

const ProofFund = (props) => {
  const classes = withStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [token, setToken] = useState(null || props.token);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [selectedBanks, setSelectedBanks] = useState([]);
  const [error, setError] = useState(false);
  const [sendReportStep, setSendReportStep] = useState(0);
  const [templateBorder, setTemplateBorder] = useState(false);
  const [stepOneValid, setStepOneValid] = useState(false);
  const [stepTwoValid, setStepTwoValid] = useState(false);
  const [msgId, setMsgId] = useState(location.pathname.slice(location.pathname.lastIndexOf('/') + 1))
  const [myAccountData, setMyAccountData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true); // todo
  const [selectedMessage, setSelectedMessage] = useState({});

  //TODO add this selectInfo to store
  const [selectInfo, setSelectInfo] = React.useState({
    address: "",
    phone: "",
    accountNumber: "",
    report_type: null,
    report_request_type: 2,
    message_body: null,
    message_subject: null,
    to_user: null,
    proof_amount: null,
    message_draft: 0,
    data: [],
  });

  const { dashboardData, dashboardDataLoading } = useSelector((state) => state);

  useEffect(() => {
    if (dashboardData === null && !dashboardDataLoading) {
      dispatch(fetchDashboardData());
    }
    console.log(dashboardData);
    if(msgId && dashboardData && dashboardData.messages) {
      const msg = dashboardData.messages.find(x => parseInt(x.id) === parseInt(msgId));
      setSelectedMessage(msg);
    }
  }, [dashboardData, dashboardDataLoading]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/myaccount`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
        }
    }).then(res => {
      if(res.status === 401) {history.push("/");}
    })
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setMyAccountData(result);
          console.log(result)
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [])

  useEffect(() => {
    if (selectInfo.to_user && selectInfo.report_type !== null) {
      setStepOneValid(true);
    }
  }, [selectInfo.to_user, selectInfo.report_type]);

  useEffect(() => {
    if (selectInfo && selectInfo.proof_amount && selectInfo.data.length > 0) {
      setStepTwoValid(true);
    } else {
      setStepTwoValid(false);
    }
  }, [selectInfo.proof_amount, selectInfo.data]);

  //TODO setup global handler for selectInfo

  const renderFormStep = () => {
    return (
      <>
        <div className={classes.recipientHeader}>
          <div className={classes.proof_report_block}>
            <h2>Proof of Funds Report</h2>
            <div className={classes.Proof_heading}>
              <p>Contact Information</p>
            </div>
            <div className={classes.address_main_block}>
              <div className={classes.address_block}>
                <h2>{myAccountData.accountInfo[0].name}</h2>
                <h3>
                  {myAccountData.accountInfo[0].address.split(",").map((line) => {
                    return (
                      <React.Fragment>
                        {line}
                        <br />
                      </React.Fragment>
                    );
                  })}
                </h3>
              </div>
              <div className={classes.address_block}>
                <h2>{myAccountData.accountInfo[0].email}</h2>
                <h3>{myAccountData.accountInfo[0].phone}</h3>
              </div>
            </div>
            <div className={classes.Proof_heading}>
              <p>Proof of Funds</p>
            </div>
            <div className={classes.amount_main_block}>
              <div className={classes.required_block}>
                {/* <p>Required Minimum</p> */}
                <p>Available Funds</p>
                <h2 className={classes.center}>${selectedMessage.report.fund_total}</h2>
              </div>
              <div className={classes.confirmed_block}>
                <div className={classes.check_circle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#fff"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
                  </svg>
                </div>
                <p className={classes.confirmed_block_p}>Confirmed</p>
              </div>
            </div>
            <div className={classes.Proof_heading}>
              <p>Message</p>
            </div>
            <div className={classes.message_main_block}>
              <p >
                {selectedMessage.message_subject}
              </p>
              <p>
               {selectedMessage.message_body}
              </p>
            </div>

            <div className={classes.bottom_button_block}>
              <button className={classes.button_main}>Export</button>
              <button className={classes.last_button}>
                Reply
                <div className="check_circle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#fff"
                  >
                    <g>
                      <rect fill="none" height="24" width="24" />
                    </g>
                    <g>
                      <g>
                        <g>
                          <path d="M22,4c0-1.1-0.9-2-2-2H4C2.9,2,2.01,2.9,2.01,4L2,22l4-4h9v-8h7V4z" />
                        </g>
                        <g>
                          <polygon points="22.5,16 20.3,16 22,12 17,12 17,18 19,18 19,23" />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={classes.sendReportRoot}>
      <Layout pageTitle={props.title} />
      <div className={classes.sendReportHeaderWrapper}>
        <Typography className={classes.sendReportTopHeader}>
          Payview Report
        </Typography>
        <Typography className={classes.sendReportHeaderText}>
          Proof of Funds
        </Typography>
      </div>
      <div className={classes.sendReportWrapper}>
        {myAccountData.accountInfo !== null && myAccountData.accountInfo !== undefined && 
          renderFormStep(sendReportStep)}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(ProofFund);
