import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Link, useHistory} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import Layout from '../Layout'
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import { sendReportData, clearSendReportError } from '../../actions/addLinkedAccounts';

const withStyles = makeStyles(() => ({
    requestReportRoot: {
        minHeight: "100vh",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center"
    },
    requestReportHeaderWrapper: {
        display: "flex",
        flexDirection: "column",
        margin: "15px"
    },
    requestReportTopHeader: {
        textAlign: "left",
        fontSize: "0.75rem"
    },
    requestReportHeaderText: {
        textAlign: "left",
        fontSize: "1.5rem"
    },
    recipientHeader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "80%",
        marginTop: "40px",
        marginLeft: "30px",
        marginBottom: "20px"
    },
    recipientHeaderText: {
        color: "#313131",
        textAlign: "left",
    },
    visibleInformation: {
        color: "gray",
        fontSize: "0.75rem",
        textAlign: "left",
        marginLeft: "5px",
        lineHeight: "2"
    },
    reportConfirmedText: {
        color: "#313131",
        fontSize: "0.75rem"
    },
    reportMessageText: {
        color: "#949494",
        fontSize: "0.75rem",
        textAlign: "left",
        margin: "15px"
    },
    visibleInfoWrapper: {
        display: "flex"
    },
    reviewReportProofAmount: {
        color: "#313131",
        marginTop: "10px"
    },
    reqMinReport: {
        display: "flex",
        flexDirection: "column",
        height: "85px",
        backgroundColor: "#B8FBE2",
        borderRadius: "16px",
        width: "50%",
        justifyContent: "center",
        alignItems: "center"
    },
    reqMinReportWrapper: {
        display: "flex",
        padding: "10px 0 10px 0"
    },
    dividerText: {
        color: "#313131",
        textAlign: "left",
        fontSize: "0.6rem",
        marginTop: "10px"
    },
    reqMinReportTitle: {
        color: "#949494",
        textAlign: "left",
        fontSize: "0.7rem",
        marginTop: "-10px"
    },
    optionalMessage: {
        color: "rgba(0, 0, 0, 0.54)",
        textAlign: "left",
        fontSize: "0.6rem",
        margin: "10px 0 10px 0"
    },
    messageSubjectHeader: {
        color: "#313131",
        textAlign: "left",
    },
    messageSubjectWrapper: {
        // display: "flex"
    },
    fullNameEmailText: {
        color: "#313131",
        textAlign: "left",
        marginRight: "50px",
        fontSize: "0.75rem",
        margin: "5px"
    },
    dbTopText: {
        paddingTop: "3%",
        paddingLeft: "5%",
        paddingRight: "3%",
        paddingRight: "2%",
        textAlign: "left"
      },
    selectTemplateWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "80%",
        marginTop: "40px",
        marginLeft: "30px",
        marginBottom: "20px"
    },
    selectTemplateHeaderText: {
        color: "#313131",
        textAlign: "left"
    },
    selectTemplateCard: {
        display: "flex",
        margin: "15px 0 15px 0",
        padding: "10px",
        borderRadius: "15px"
    },
    selectInformationWrapper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "80%",
        marginTop: "40px",
        marginLeft: "30px",
        marginBottom: "20px"
    },
    checkIcon: {
        width: "15px",
        height: "15px",
        margin: "5px"
    },
    checkIconReport: {
        width: "15px",
        height: "15px",
        margin: "5px 0 5px 0"
    },
    checkIconReportConfirmed: {
        width: "40px",
        height: "40px",
        margin: "5px 0 5px 0"
    },
    reportConfirmedWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50%"
    },
    optionalInfoCheckboxText: {
        color: "#32A19F"
    },
    optionalInfoCheckbox: {
        color: "#313131",
        "& span": {
            fontSize: "0.75rem"
        }
    },
    optionalInfoCheckboxColor: {
        color: "#32A19F"
    },
    nextStepButton: {
        display: "flex",
        justifyContent: "flex-end",
    },
    reviewButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    formControl: {
        width: "100%"
    },
    selectTemplateRadioLabel: {
        fontSize: "0.75rem",
        textAlign: "left"
    },
    radioRoot: {
        '&$checked': {
            color: "#32A19F"
        }
    },
    checked: {},
    selectMinimumCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 0px 10px 0px",
        padding: "15px",
        backgroundColor: "#d2e3f66b"
    },
    selectMinimumInput: {
        width: "80%",
        "& label": {
            width: "130%",
            textAlign: "center",
        }
    },
    selectMinInputLabel: {
        color: "rgba(0, 0, 0, 0.54) !important"
    },
    requiredMinHeader: {
        textAlign: "center"
    },
    minAmountInput: {
        "&:before": {
            borderBottom: "1px solid #E4E2E2"
        },
        "&:after": {
            borderBottom: "1px solid #E4E2E2"
        }
    },
    selectAccountsTopCardWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    totalSelectedHeader: {
        fontSize: "0.75rem",
        color: "rgba(0, 0, 0, 0.54)"
    },
    accountsWrapper: {
        marginBottom: "10px",
        marginLeft: "45px"
    },
    subAccountsWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    linkedAccountsBankname: {
        fontSize: "0.75rem",
        color: "#313131",
    },
    linkedAccountsSubaccounts: {
        color: "#949494",
        fontSize: "0.65rem",
        margin: "10px 0"
    },
    linkedAccountsSubaccountsWrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginRight: "16px"
    },
    accountsNumber: {
        color: "black",
        fontSize: "0.65rem",
        marginBottom: "10px",
        textAlign: "left",
    },
    accountsDivider: {
        marginLeft: "-45px"
    },
    selectAccountsCard: {
        margin: "20px 0 20px 0"
    },
    bankTitleWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    bankTitleSumWrapper: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "10px"
    },
    cancelButtonWrapper: {
        display: "flex",
        margin: "20px 0 20px 0",
        "& a ": {
            textDecoration: "none"
        }
    },
    cancelButtonRoot: {
        backgroundColor: "rgba(210,227,246,0.30)",
        borderRadius: "10px",
        height: "50px",
        width: "100px",
        "& span": {
            textTransform: "none"
        }
    },
    messageBodyRoot: {
        "& > div": {
            // height: "150px",
            display: "flex",
            flexDirection: "column"
        },
        marginTop: "2%",
        '@media (max-width: 899px)' : {
            paddingRight: "1%",
          }
    },
    messageBodyColor: {
        // "&$focused": {
        //     color: "red"
        // }
    },
    reviewButtonText: {
        color: "#32A19F",
        textTransform: "none"
    },
    sendReportButtonWrapper: {
        display: "flex",
        justifyContent: "flex-end"
    },
    sendReportButton: {
        backgroundColor: "transparent",
        "& span": {
            textTransform: "none"
        }
    },
    selectTemplateBorder: {
        border: "2px solid #32A19F",
        borderRadius: "16px"
    },
    loadingDialog: {
        backgroundColor: "#000",
        opacity: ".7",
        color: 'white',
        paddingBottom: "20px"
      },
      spinner: {
          marginRight: '20px'
          
      },
      dialogText: {
          fontSize: '1.15rem'
      },
      dialogButton: {
        color: "white"
      },
      requestReportWrapper: {
        background: "#ffffff",
        borderRadius: "51px",
        borderTopRightRadius: "51px",
        marginLeft: '5%',
        marginRight: '5%',
        background: "#ffffff",
        '@media (max-width: 899px)' : {
            marginLeft: '0%',
            marginRight: '0%',
            paddingLeft: '5%',
            paddingRight: '5%',
            height: '100%',
            minHeight: '6000vh'
          }
      },
      styledFormLabel: {
        fontWeight: "bold",
        color: "#32A19F",
        fontSize: "1.2em"
      },
      dontShowOnMobile: {
        '@media (max-width: 899px)' : {
          display: 'none'
        }
      },
      headerText: {
        marginBottom: 0,
        marginTop: 0,
        fontWeight: 400,
        '@media (max-width: 899px)' : {
        //   justifyContent: "center",
          paddingBottom: '3%'
        }
      },
      leftGrid: {
        paddingLeft: '5%', paddingTop: '2%', marginTop: '2%', marginBottom: '5%', paddingLeft: '4% !important',
        '@media (max-width: 899px)' : {
          borderRight: 'none',
          marginBottom: "0%"
        }
      },
      leftGridTitle: {
        'margin':  0,'fontWeight': 'bold','fontSize': '70%', marginBottom: '5%',
        '@media (max-width: 899px)' : {
          marginTop: '3%',
          marginBottom: '5%',
        //   paddingLeft: '1%',
          fontSize: '100%'
        }
      },
      rightGrid: {
        color: '#000', textAlign: 'left', paddingLeft: '3%',  marginTop: '2%', maxHeight: '60vh', overflowX: "hidden", 
        '@media (max-width: 899px)' : {
          maxHeight: '6000vh'
        }
      },
      rightGridTitle: {
        'margin':  0,'fontWeight': 'bold','fontSize': '70%', marginBottom: '5%', 
        '@media (max-width: 899px)' : {
          fontSize: '100%',
          marginBottom: '5%'
        }
      },
      mainGrid: {
        color: '#000', textAlign: 'left', paddingRight: "3%", paddingBottom: "2%",
        '@media (max-width: 899px)' : {
          paddingBottom: "0%"
        }
      },
      cardTopLine: {
        fontWeight: "bold", 
        fontSize: ".4em",
        '@media (max-width: 899px)' : {
          fontSize: ".7em",
        }
      },
      cardSecondaryLine: {
        fontSize: ".35em",
        '@media (max-width: 899px)' : {
          fontSize: ".6em",
        }
      },
      textField: {
        '@media (max-width: 899px)' : {
            fontSize: "1em",
          }
      },
      formField: {
        display: "flex", marginBottom: "10%", marginRight: "3%",'@media (max-width: 899px)' : {fontSize: "1.35em"}
      },
      personalNoteLabel: {fontWeight: "bold", color: "#32A19F", fontSize: ".6em", paddingTop:'3%', '@media (max-width: 899px)' : {fontSize: ".8em"}},
      cancelButton: {backgroundColor: "#EFF3F8",  color: "#32A19F",  fontWeight: "bold", textTransform: "none", paddingTop:"12%", paddingBottom:"12%"},
      requestButton: {backgroundColor: "#32A19F", padding: "1%", paddingLeft: "3%", paddingRight: "3%", color: "#fff", marginLeft: "2%", fontWeight: "bold", textTransform: "none"}
}));

const mapStateToProps = (state) => ({
    token: state.token,
    dashboardData: state.dashboardData
})

const RequestReport = (props) => {
    const history = useHistory();
    const classes = withStyles();
    const dispatch = useDispatch();
    const [token, setToken] = useState(null || props.token);
    const [sendReportStep, setSendReportStep] = useState(0);
    const [selectInfo, setSelectInfo] = useState({
        address: false,
        phone: false,
        accountNumber: false,
        transactions: false,
        report_type: 1,
        report_request_type: 1,
        message_body: null,
        message_subject: "",
        to_user_email: "",
        requestee_name: "",
        to_user_first_name: "",
        to_user_last_name: "",
        requester_name: "",
        agent_first_name: "",
        agent_last_name: "",
        proof_amount: 0,
        message_draft: 0,
        data: []
    });
    const [selectedReportType, setSelectedReportType] = useState('');
    const [templateBorder, setTemplateBorder] = useState(false);
    const [stepZeroValid, setStepZeroValid] = useState(false);
    const [stepOneValid, setStepOneValid] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState();
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [myAccountData, setMyAccountData] = useState(null);
    const [hasTriedToProceed, setHasTriedToProceed] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const { message_body, to_user_email, data, proof_amount, agent_first_name, agent_last_name, to_user_first_name, to_user_last_name, requestee_name, requester_name} = selectInfo;

    const { reportSent, errorSendingReport, errorMsg } = useSelector((state) => state);
    const [showErrorModal, setShowErrorModal] = useState(false);

    useEffect(() => {
        if (getEmailErrorMessage(selectInfo.to_user_email) === "") {
            setStepZeroValid(true)
        } else {
            setStepZeroValid(false);
        }

    }, [selectInfo.to_user_email])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/myaccount`, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            }
        })
          .then(res => {
            if(res.status === 401) {history.push("/");}
          })
          .then(res => res.json())
          .then(
            (result) => {
              setMyAccountData(result);
              console.log(result)
            },
            (error) => {
              setError(error);
            }
          )
      }, [])

    useEffect(() => {
        if (selectInfo.proof_amount > 0 && selectInfo.report_type) {
            setStepOneValid(true)
        } else {
            setStepOneValid(false)
        }

    }, [selectInfo.proof_amount, selectInfo.report_type])


    useEffect(() => {
        if(errorSendingReport) {
            setShowLoadingModal(false);
            setShowErrorModal(true);
        } 
    }, [errorSendingReport]);

    useEffect(() => {
        if(errorMsg) {
            console.log('setting message')
            setMessage(errorMsg)
        }
    }, [errorMsg]);
    

    const handleRequesteeEmail = (event) => {
        setSelectInfo({ ...selectInfo, to_user_email: event.target.value });
    }
    const handleRequesterName = (event) => {
        setSelectInfo({ ...selectInfo, 
            requester_name: event.target.value, 
            agent_first_name: event.target.value.split(' ')[0], 
            agent_last_name: event.target.value.split(' ')[1]
        });
    }
    const handleRequesteeName = (event) => {
        setSelectInfo({ ...selectInfo, 
            requestee_name: event.target.value, 
            to_user_first_name: event.target.value.split(' ')[0], 
            to_user_last_name: event.target.value.split(' ')[1] 
        });
    }
    
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(event)
        setSendReportStep((step) => step + 1)
        console.log(selectInfo)
    };

    const handleReportType = (event) => {
        setSelectedReportType(event.target.value);
        setTemplateBorder(event.target.value);
        setSelectInfo({ ...selectInfo, report_type: parseInt(event.target.value, 10) });
        console.log(event.target.value)
        setError(false);
    };

    const handleAddress = (event) => {
        setSelectInfo({ ...selectInfo, [event.target.name]: event.target.checked });
    };

    const handlePhone = (event) => {
        setSelectInfo({ ...selectInfo, [event.target.name]: event.target.checked });
    };

    const handleAccountNumber = (event) => {
        setSelectInfo({ ...selectInfo, [event.target.name]: event.target.checked });
    };

    const handleTransactions = (event) => {
        // setSelectInfo({ ...selectInfo, [event.target.name]: event.target.checked });
    };

    const handleRequiredMin = (event) => {
        setSelectInfo({ ...selectInfo,proof_amount: event.target.value })
    }

    const amtFocus = () => {
        if(selectInfo.proof_amount === 0) {
            setSelectInfo({ ...selectInfo,proof_amount:'' })
        }
    }
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const formatNumbers = (num) => {
        if (num % 1 !== 0) {
            let localeNum = num.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})
            return localeNum;
        } else {
            return num.toLocalString();
        }
    }

    const sendReportBack = () => {
        setSendReportStep((step) => step - 1)
    }

    const saveReportDraft = () => {
        setSelectInfo({ ...selectInfo, message_draft: 0 })
    }

    

    const sendReport = useCallback(() => {
        setHasTriedToProceed(true);
        setShowLoadingModal(true);
        console.log(selectInfo);
        dispatch(sendReportData(selectInfo))
    }, [dispatch, selectInfo]);

    const handleMessageBody = (event) => {
        setSelectInfo({ ...selectInfo, message_body: event.target.value });
    }

    const handleShowPreview = (event) => {
        setShowPreview(true);
    }

    const handleErrorModalClose= () => {
        setShowErrorModal(false);
        dispatch(clearSendReportError());
    };
    
    const getEmailErrorMessage= (email) => {
        if(email && !validateEmail(email)) {
            return "Enter a valid email address."
        } 
        else if(myAccountData && myAccountData.accountInfo && myAccountData.accountInfo.length > 0 && myAccountData.accountInfo[0].email === email) {
            return "Enter a different email from your account email."
        }
        return '';
    };
    
    const emailText1 = agent_first_name + " " + agent_last_name + " has requested your proof of funds report."
    const emailText2 = "Hello " + to_user_first_name + " " + to_user_last_name + ","
    const emailText3 = "PayView has made it simple, safe, and secure for you to submit your proof of funds for the requested amount of $" + proof_amount + "."

    return (
        <div className={classes.requestReportRoot}>
            <Layout pageTitle={props.title} />
            <div className={classes.dbTopText} style={{justifyContent: "left", display: "flex"}}>
                <Link to="/dashboard" className={classes.dontShowOnMobile} style={{textDecoration: "none", display: "flex", marginRight: "3%", paddingTop: "1%"}}>
                    <h3 className={classes.dontShowOnMobile} style={{height: '100%', color: "#fff", cursor: "pointer"}}>&lt;</h3>
                </Link>
                <div style={{height: '100%'}}>
                    <h2 className={classes.headerText}>Request Proof of Funds</h2>
                    <p className={classes.dontShowOnMobile} style={{marginBottom: '3%', fontSize: '70%', marginTop: "0", fontWeight: "400"}}>Quickly and securely request the necessary documentation to verify your client's purchasing power.</p>
                </div>
            </div>
            <div className={classes.requestReportWrapper}>
                {!showPreview && 
                <div>
                <Grid container className={classes.mainGrid} spacing={2} style={{color: '#000', textAlign: 'left'}}>
                    <Grid item className={classes.leftGrid} xs={12} md={6}>
                        <p className={classes.leftGridTitle} >Contacts</p>
                        <form className={classes.formField}>
                            <TextField
                                fullWidth={true}
                                id="recipient"
                                label="Your Contact Information"
                                value={requester_name}
                                onChange={handleRequesterName}
                                placeholder='First Last'
                                className={classes.textField}
                                InputLabelProps={{
                                    style: {fontWeight: "bold", color: "#32A19F", fontSize: ".8em", paddingBottom: "1%", borderColor: "red"}
                                }}
                                error={hasTriedToProceed && !!(!stepZeroValid || !requester_name)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                }}
                            />
                        </form>
                        <form className={classes.formField}>
                            <TextField
                                fullWidth={true}
                                id="recipient"
                                label="Client name"
                                value={requestee_name}
                                placeholder='First Last'
                                onChange={handleRequesteeName}
                                className={classes.textField}
                                InputLabelProps={{
                                    style: {fontWeight: "bold", color: "#32A19F", fontSize: ".8em", paddingBottom: "1%", borderColor: "red"}
                                }}
                                error={hasTriedToProceed && !!(!stepZeroValid || !requestee_name)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // inputMode: 'numeric'
                                }}
                            />
                        </form>
                        <form className={classes.formField}>
                            <TextField
                                fullWidth={true}
                                id="recipient"
                                label="Email"
                                value={to_user_email}
                                onChange={handleRequesteeEmail}
                                className={classes.textField}
                                placeholder='name@email.com'
                                InputLabelProps={{
                                    style: {fontWeight: "bold", color: "#32A19F", fontSize: ".8em", paddingBottom: "1%", borderColor: "red"}
                                }}
                                error={hasTriedToProceed && !!(!stepZeroValid || !to_user_email)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    // inputMode: 'numeric'
                                }}
                            />
                        </form>
                    </Grid>
                    <Grid item className={classes.rightGrid} xs={12} md={6} style={{color: '#000', textAlign: 'left', paddingLeft: '3%', marginTop: '2%'}} >
                        <p className={classes.rightGridTitle}>Message</p>
                        <form className={classes.formField} style={{ marginRight: "3%",}}>
                            <TextField
                                fullWidth={true}
                                id="required-amount"
                                label="Minimum Required Funds"
                                value={proof_amount}
                                onChange={handleRequiredMin}
                                className={classes.textField}
                                onFocus={amtFocus}                              
                                InputLabelProps={{
                                    style: {fontWeight: "bold", color: "#32A19F", fontSize: ".8em"}
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    inputMode: 'numeric'
                                }}
                            />
                        </form>
                        <label className={classes.personalNoteLabel}>Add a Personal Note</label>
                        <form className={classes.formField}>
                            <TextField
                                    id="outlined-multiline-static"
                                    multiline={true}
                                    minRows={3}
                                    maxRows={3}
                                    value={message_body}
                                    onChange={handleMessageBody}
                                    fullWidth={true}
                                    variant="outlined"
                                    autoFocus={false}
                                    placeholder="Type your message here..."
                                    classes={{
                                        root: classes.messageBodyRoot,
                                    }}
                                    InputLabelProps={{
                                        style: {fontWeight: "bold", color: "#32A19F", fontSize: ".8em"}
                                    }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"></InputAdornment>
                                    }}
                                >
                                </TextField>
                        </form>
                    </Grid>
                </Grid>
                <div style={{display: "flex", alignItems: "right", justifyContent: "right", marginRight:"5%", paddingBottom: "4%"}}>
                    <Link to="/dashboard" style={{textDecoration: "none", paddingLeft: "2%", paddingRight: "2%", marginLeft: "1%",}}>
                        <Button className={classes.cancelButton}> &nbsp;&nbsp;&lt; Cancel &nbsp;&nbsp;</Button>
                    </Link>
                    <Button disabled={proof_amount == 0 || to_user_email == "" || requester_name == "" || requestee_name == "" } onClick={() => handleShowPreview()} className={classes.requestButton}>Preview Request &gt;</Button>
                </div>
                </div>
                }
                {showPreview && 
                <div>
                    <div style={{width: '40%', marginLeft: '30%', marginRight: '30%', paddingTop: '1%', textAlign: 'left'}}>
                        <p style={{color: 'black', marginBottom: '2%', fontSize: '80%', fontWeight: 'bold'}}>Email Preview</p>
                        <div style={{border: 'rgba(210,227,246,0.30) thin solid', overflowY: 'scroll', minHeight: "38vh", backgroundColor:"rgba(210,227,246,0.30)"}}>
                            <img
                                style={{width: '100%'}}
                                src="/emailHeader.png"
                            />
                            <div style={{width: '100%', paddingLeft: '6%', marginRight: '6%', marginTop: '-6%', backgroundColor: 'white', position: 'relative', top: '-6% !important'}}>
                                <p style={{color: 'black', marginBottom: '2%', fontSize: '60%', fontWeight: 'bold', maxWidth: '90%', paddingTop: '4%'}}>{emailText1}</p>
                                <p style={{color: 'black', marginBottom: '2%', fontSize: '40%', maxWidth: '90%'}}>{emailText2}</p>
                                <p style={{color: 'black', marginBottom: '2%', fontSize: '40%', maxWidth: '90%'}}>{emailText3}</p>
                                <p style={{color: 'black', marginBottom: '2%', fontSize: '40%', maxWidth: '90%'}}>{message_body}</p>
                                <Button disabled={true} className={classes.requestButton} style={{fontSize: '30%', color: 'white', marginBottom: '3%'}}>Securely Send my Proof of Funds</Button>
                            </div>
                            <div style={{ paddingLeft: '2%', paddingRight: '2%'}}>
                                <p style={{fontSize: '30%', color: 'black'}}>
                                    This is a secure and confidential process. PayView only accesses the total amount available in your accounts, and we do not track or view any transactions. We will never share or sell your financial data.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{paddingBottom:'3%', paddingTop: '3%'}}>
                        <Button className={classes.requestButton} style={{backgroundColor: "rgba(210,227,246,0.30)", color: "#32A19F"}} onClick={()=>setShowPreview(false)}> &nbsp;&nbsp;&lt; Edit &nbsp;&nbsp;</Button>
                        <Button disabled={proof_amount == 0 || to_user_email == "" || agent_first_name == "" || to_user_first_name == "" || agent_last_name == "" || to_user_last_name == "" } onClick={() => sendReport()} className={classes.requestButton}>Send &gt;</Button>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(RequestReport);